var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { truncated: !_vm.shaveDone },
      style: { maxHeight: _vm.maxHeight - 16 + "px" },
    },
    [
      _vm.viewAllText
        ? _c("div", [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")])
        : _c("div", { ref: "shaveEl", staticClass: "truncated" }, [
            _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
          ]),
      _vm.showViewMore && (_vm.textIsTruncated || _vm.viewAllText)
        ? _c(
            "div",
            { staticClass: "show-more" },
            [
              _c("KButton", {
                attrs: {
                  appearance: "basic-link",
                  text: _vm.viewAllText
                    ? _vm.$coreString("viewLessAction")
                    : _vm.coreString("viewMoreAction"),
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    _vm.viewAllText = !_vm.viewAllText
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }