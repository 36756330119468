var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SelectSourceModal",
    {
      attrs: {
        submitDisabled: _vm.formIsDisabled,
        showLoadingMessage: _vm.formIsDisabled && !_vm.initialDelay,
      },
      on: { submit: _vm.handleSubmit, cancel: _vm.handleCancel },
    },
    [
      _c("KRadioButton", {
        attrs: {
          label: _vm.$tr("dataPortalLabel"),
          value: _vm.SyncSources.PORTAL,
          disabled: _vm.portalIsOffline || _vm.formIsDisabled,
          autofocus: !_vm.portalIsOffline,
          description: _vm.$tr("dataPortalDescription"),
        },
        model: {
          value: _vm.source,
          callback: function ($$v) {
            _vm.source = $$v
          },
          expression: "source",
        },
      }),
      _c("KRadioButton", {
        attrs: {
          label: _vm.$tr("localNetworkLabel"),
          value: _vm.SyncSources.PEER,
          disabled: _vm.formIsDisabled,
          description: _vm.$tr("localNetworkDescription"),
        },
        model: {
          value: _vm.source,
          callback: function ($$v) {
            _vm.source = $$v
          },
          expression: "source",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }