var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c("UiToolbar", {
        style: {
          height: _vm.topBarHeight + "px",
          backgroundColor: _vm.isFullscreen
            ? _vm.$themeTokens.appBar
            : _vm.$themeTokens.appBarFullscreen,
        },
        attrs: {
          title: _vm.appBarTitle,
          textColor: "white",
          type: "clear",
          showIcon: _vm.showIcon,
        },
        on: {
          "nav-icon-click": function ($event) {
            return _vm.$emit("navIconClick")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "icon",
              fn: function () {
                return [
                  _vm.hasRoute
                    ? _c(
                        "router-link",
                        {
                          staticClass: "link",
                          class: _vm.$computedClass(_vm.linkStyle),
                          attrs: { to: _vm.route },
                        },
                        [
                          _vm.icon === "close"
                            ? _c("KIconButton", {
                                attrs: {
                                  ariaLabel: _vm.coreString("closeAction"),
                                  icon: "close",
                                  color: _vm.$themeTokens.textInverted,
                                  tabindex: "-1",
                                },
                              })
                            : _c("KIconButton", {
                                attrs: {
                                  icon: "back",
                                  ariaLabel: _vm.coreString("goBackAction"),
                                  color: _vm.$themeTokens.textInverted,
                                },
                              }),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _vm.icon === "close"
                            ? _c("KIconButton", {
                                attrs: {
                                  ariaLabel: _vm.coreString("closeAction"),
                                  icon: "close",
                                  color: _vm.$themeTokens.textInverted,
                                  tabindex: "-1",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("navIconClick")
                                  },
                                },
                              })
                            : _c("KIconButton", {
                                attrs: {
                                  icon: "back",
                                  ariaLabel: _vm.coreString("goBackAction"),
                                  color: _vm.$themeTokens.textInverted,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("navIconClick")
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [_vm._t("actions")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }