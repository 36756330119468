var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title:
          _vm.$attrs.title ||
          _vm.getCommonSyncString("selectNetworkAddressTitle"),
        submitText: _vm.coreString("continueAction"),
        cancelText: _vm.coreString("cancelAction"),
        size: "medium",
        submitDisabled: _vm.formDisabled || _vm.submitDisabled,
      },
      on: {
        submit: _vm.handleSubmit,
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "KFixedGrid",
                { staticClass: "actions", attrs: { numCols: "4" } },
                [
                  _c(
                    "KFixedGridItem",
                    { attrs: { span: "1" } },
                    [
                      _c("transition", { attrs: { name: "spinner-fade" } }, [
                        _vm.isFetching || _vm.isChecking
                          ? _c(
                              "div",
                              [
                                _c("KLabeledIcon", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c("KCircularLoader", {
                                              staticClass: "loader",
                                              attrs: { size: 16, stroke: 6 },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4133780035
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "KFixedGridItem",
                    { attrs: { span: "3", alignment: "right" } },
                    [
                      _c(
                        "KButtonGroup",
                        { staticStyle: { "margin-top": "8px" } },
                        [
                          _c("KButton", {
                            attrs: {
                              text: _vm.coreString("cancelAction"),
                              appearance: "flat-button",
                              disabled:
                                _vm.formDisabled || _vm.isSubmitChecking,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("cancel")
                              },
                            },
                          }),
                          _c("KButton", {
                            attrs: {
                              text: _vm.coreString("continueAction"),
                              primary: true,
                              disabled: _vm.formDisabled || _vm.submitDisabled,
                              type: "submit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _vm.filterLODAvailable
          ? _c("p", [
              _vm._v("\n      " + _vm._s(_vm.$tr("lodSubHeader")) + "\n    "),
            ])
          : _vm._e(),
        _vm.hasFetched && !_vm.devices.length
          ? _c("p", [
              _vm._v("\n      " + _vm._s(_vm.$tr("noDeviceText")) + "\n    "),
            ])
          : _vm._e(),
        _vm.uiAlertProps
          ? _c(
              "UiAlert",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showUiAlerts,
                    expression: "showUiAlerts",
                  },
                ],
                attrs: { type: _vm.uiAlertProps.type, dismissible: false },
              },
              [
                _vm._v("\n      " + _vm._s(_vm.uiAlertProps.text) + "\n      "),
                _vm.fetchFailed || _vm.deletingFailed
                  ? _c("KButton", {
                      attrs: {
                        appearance: "basic-link",
                        text: _vm.$tr("refreshDevicesButtonLabel"),
                      },
                      on: { click: _vm.forceFetch },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.savedDevices, function (d, idx) {
          return [
            _c(
              "div",
              { key: "div-" + idx },
              [
                _c("KRadioButton", {
                  key: idx,
                  staticClass: "radio-button",
                  attrs: {
                    value: _vm.canLearnerSignUp(d.id) ? d.id : false,
                    label: d.nickname,
                    description: d.base_url,
                    disabled:
                      !_vm.canLearnerSignUp(d.id) ||
                      _vm.formDisabled ||
                      !_vm.isDeviceAvailable(d.id),
                  },
                  model: {
                    value: _vm.selectedDeviceId,
                    callback: function ($$v) {
                      _vm.selectedDeviceId = $$v
                    },
                    expression: "selectedDeviceId",
                  },
                }),
                _c("KButton", {
                  key: "forget-" + idx,
                  staticClass: "remove-device-button",
                  attrs: {
                    text: _vm.coreString("removeAction"),
                    appearance: "basic-link",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removeSavedDevice(d.id)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        }),
        _vm.savedDevices.length > 0 && _vm.discoveredDevices.length > 0
          ? _c("hr", {
              style: {
                border: 0,
                borderBottom: "1px solid " + _vm.$themeTokens.fineLine,
              },
            })
          : _vm._e(),
        _vm._l(_vm.discoveredDevices, function (d) {
          return [
            _c(
              "div",
              { key: "div-" + d.id },
              [
                _c("KRadioButton", {
                  key: d.id,
                  staticClass: "radio-button",
                  attrs: {
                    value: _vm.canLearnerSignUp(d.id) ? d.instance_id : false,
                    label: _vm.formatNameAndId(d.device_name, d.id),
                    description: _vm.formatBaseDevice(d),
                    disabled:
                      !_vm.canLearnerSignUp(d.id) ||
                      _vm.formDisabled ||
                      _vm.fetchFailed ||
                      !_vm.isDeviceAvailable(d.id),
                  },
                  model: {
                    value: _vm.selectedDeviceId,
                    callback: function ($$v) {
                      _vm.selectedDeviceId = $$v
                    },
                    expression: "selectedDeviceId",
                  },
                }),
              ],
              1
            ),
          ]
        }),
      ],
      _c(
        "KButtonGroup",
        { staticClass: "under-buttons" },
        [
          _vm._t("underbuttons"),
          _c("KButton", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.newDeviceButtonDisabled && !_vm.formDisabled,
                expression: "!newDeviceButtonDisabled && !formDisabled",
              },
            ],
            staticClass: "new-device-button",
            attrs: {
              text: _vm.getCommonSyncString("addNewAddressAction"),
              appearance: "basic-link",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click_add_address")
              },
            },
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }