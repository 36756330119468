var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Navbar", [
    _c(
      "div",
      { ref: "navContainer", staticClass: "navcontainer" },
      _vm._l(_vm.allLinks, function (link, index) {
        return _c(
          "NavbarLink",
          {
            key: index,
            ref: "navLinks",
            refInFor: true,
            attrs: { title: link.title, link: link.link },
          },
          [_c("KIcon", { attrs: { icon: link.icon, color: link.color } })],
          1
        )
      }),
      1
    ),
    _vm.overflowMenuLinks && _vm.overflowMenuLinks.length > 0
      ? _c(
          "span",
          [
            _c("KIconButton", {
              attrs: {
                tooltip: _vm.coreString("moreOptions"),
                tooltipPosition: "top",
                ariaLabel: _vm.coreString("moreOptions"),
                icon: "optionsHorizontal",
                appearance: "flat-button",
                color: _vm.color,
                primary: false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "menu",
                    fn: function () {
                      return [
                        _c("KDropdownMenu", {
                          attrs: {
                            primary: false,
                            disabled: false,
                            hasIcons: true,
                            options: _vm.overflowMenuLinks,
                          },
                          on: { select: _vm.handleSelect },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                843128583
              ),
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }