var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FocusTrap",
    {
      on: {
        shouldFocusFirstEl: _vm.focusFirstEl,
        shouldFocusLastEl: _vm.focusLastEl,
      },
    },
    [
      _c(
        "KModal",
        {
          attrs: {
            title: _vm.coreString("changeLanguageOption"),
            submitText: _vm.coreString("confirmAction"),
            cancelText: _vm.coreString("cancelAction"),
            size: 600,
          },
          on: { cancel: _vm.cancel, submit: _vm.setLang },
        },
        [
          _c(
            "KGrid",
            _vm._l(_vm.splitLanguageOptions, function (languageCol, index) {
              return _c(
                "KGridItem",
                {
                  key: index,
                  class: { "offset-col": _vm.windowIsSmall && index === 1 },
                  attrs: { layout8: { span: 4 }, layout12: { span: 6 } },
                },
                _vm._l(languageCol, function (language) {
                  return _c("KRadioButton", {
                    key: language.id,
                    ref: "languageItem",
                    refInFor: true,
                    staticClass: "language-name",
                    attrs: {
                      value: language.id,
                      label: language.lang_name,
                      title: language.english_name,
                    },
                    model: {
                      value: _vm.selectedLanguage,
                      callback: function ($$v) {
                        _vm.selectedLanguage = $$v
                      },
                      expression: "selectedLanguage",
                    },
                  })
                }),
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }