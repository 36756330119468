var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: [_vm.showSelectAllCheckbox ? { marginTop: "-44px" } : {}] },
    [
      _vm.showSelectAllCheckbox
        ? _c("KCheckbox", {
            staticClass: "select-all",
            style: { color: this.$themeTokens.annotation },
            attrs: {
              label: _vm.$tr("selectAllLabel"),
              showLabel: true,
              checked: _vm.allAreSelected,
              disabled: _vm.disabled || !_vm.users || _vm.users.length === 0,
              "data-test": "selectAllCheckbox",
            },
            on: {
              change: function ($event) {
                return _vm.selectAll($event)
              },
            },
          })
        : _vm._e(),
      _c("CoreTable", {
        attrs: { emptyMessage: _vm.emptyMessage, dataLoading: _vm.dataLoading },
        scopedSlots: _vm._u(
          [
            {
              key: "headers",
              fn: function () {
                return [
                  _c(
                    "th",
                    {
                      style: { minWidth: "32px" },
                      attrs: { "data-test": "fullNameHeader" },
                    },
                    [
                      _vm.selectable
                        ? _c("span", { staticClass: "visuallyhidden" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$tr("selectUserBy")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          class: { visuallyhidden: _vm.showSelectAllCheckbox },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.coreString("fullNameLabel")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("th", [
                    _c(
                      "span",
                      {
                        staticClass: "visuallyhidden",
                        attrs: { "data-test": "roleHeader" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("role")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                  _c("th", { attrs: { "data-test": "usernameHeader" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.coreString("usernameLabel")) +
                        "\n      "
                    ),
                  ]),
                  _vm.$scopedSlots.info
                    ? _c("th", [
                        _vm._v(
                          "\n        " + _vm._s(_vm.infoDescriptor) + "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm.showDemographicInfo
                    ? [
                        _c(
                          "th",
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.coreString("identifierLabel"))),
                            ]),
                            _c("CoreInfoIcon", {
                              staticClass: "tooltip",
                              attrs: {
                                iconAriaLabel: _vm.coreString(
                                  "identifierAriaLabel"
                                ),
                                tooltipText:
                                  _vm.coreString("identifierTooltip"),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("th", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.coreString("genderLabel")) +
                              "\n        "
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.coreString("birthYearLabel")) +
                              "\n        "
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.$scopedSlots.action
                    ? _c("th", { staticClass: "user-action-button" }, [
                        _c("span", { staticClass: "visuallyhidden" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.coreString("userActionsColumnHeader")
                              ) +
                              "\n        "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "tbody",
              fn: function () {
                return [
                  _c(
                    "tbody",
                    _vm._l(_vm.users, function (user) {
                      return _c(
                        "tr",
                        { key: user.id, style: _vm.isSelectedStyle(user.id) },
                        [
                          _c(
                            "td",
                            [
                              _vm.selectable && _vm.enableMultipleSelection
                                ? _c(
                                    "KCheckbox",
                                    {
                                      staticClass: "user-checkbox",
                                      attrs: {
                                        disabled: _vm.disabled,
                                        checked: _vm.userIsSelected(user.id),
                                        "data-test": "userCheckbox",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.selectUser(user.id, $event)
                                        },
                                      },
                                    },
                                    [
                                      _c("KLabeledIcon", {
                                        attrs: {
                                          icon: _vm.isCoach
                                            ? "coach"
                                            : "person",
                                          label: user.full_name,
                                          "data-test": "fullName",
                                        },
                                      }),
                                      _c("UserTypeDisplay", {
                                        staticClass: "role-badge",
                                        style: {
                                          color: _vm.$themeTokens.textInverted,
                                          backgroundColor:
                                            _vm.$themeTokens.annotation,
                                        },
                                        attrs: {
                                          "aria-hidden": "true",
                                          userType: user.kind,
                                          omitLearner: true,
                                          "data-test": "userRoleBadge",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.selectable && !_vm.enableMultipleSelection
                                ? _c(
                                    "KRadioButton",
                                    {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        value: user.id,
                                        currentValue: _vm.firstSelectedUser,
                                        label: "",
                                        "data-test": "userRadioButton",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.selectSingleUser(user.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("KLabeledIcon", {
                                        style: { color: _vm.$themeTokens.text },
                                        attrs: {
                                          icon: _vm.isCoach
                                            ? "coach"
                                            : "person",
                                          label: user.full_name,
                                          "data-test": "fullName",
                                        },
                                      }),
                                      _c("UserTypeDisplay", {
                                        staticClass: "role-badge",
                                        style: {
                                          color: _vm.$themeTokens.textInverted,
                                          backgroundColor:
                                            _vm.$themeTokens.annotation,
                                        },
                                        attrs: {
                                          "aria-hidden": "true",
                                          userType: user.kind,
                                          omitLearner: true,
                                          "data-test": "userRoleBadge",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : [
                                    _c("KLabeledIcon", {
                                      style: { color: _vm.$themeTokens.text },
                                      attrs: {
                                        icon: _vm.isCoach ? "coach" : "person",
                                        label: user.full_name,
                                        "data-test": "fullName",
                                      },
                                    }),
                                    _c("UserTypeDisplay", {
                                      staticClass: "role-badge",
                                      style: {
                                        color: _vm.$themeTokens.textInverted,
                                        backgroundColor:
                                          _vm.$themeTokens.annotation,
                                      },
                                      attrs: {
                                        "aria-hidden": "true",
                                        userType: user.kind,
                                        omitLearner: true,
                                        "data-test": "userRoleBadge",
                                      },
                                    }),
                                  ],
                            ],
                            2
                          ),
                          _c(
                            "td",
                            {
                              staticClass: "visuallyhidden",
                              attrs: { "data-test": "userRoleLabel" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.typeDisplayMap[user.kind]) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c(
                            "td",
                            {
                              style: { color: _vm.$themeTokens.text },
                              attrs: { "data-test": "username" },
                            },
                            [
                              _c("span", { attrs: { dir: "auto" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(user.username) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm.showDemographicInfo
                            ? [
                                _c(
                                  "td",
                                  { staticClass: "id-col" },
                                  [
                                    _c("KOptionalText", {
                                      attrs: {
                                        text: user.id_number
                                          ? user.id_number
                                          : "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("GenderDisplayText", {
                                      attrs: { gender: user.gender },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c("BirthYearDisplayText", {
                                      attrs: { birthYear: user.birth_year },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm.$scopedSlots.info
                            ? _c(
                                "td",
                                [_vm._t("info", null, { user: user })],
                                2
                              )
                            : _vm._e(),
                          _vm.$scopedSlots.action
                            ? _c(
                                "td",
                                { staticClass: "core-table-button-col" },
                                [_vm._t("action", null, { user: user })],
                                2
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }