var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "ScrollingHeader",
        { attrs: { scrollPosition: 0 } },
        [
          _c("ImmersiveToolbar", {
            ref: "appBar",
            attrs: {
              appBarTitle: !_vm.loading ? _vm.appBarTitle : "",
              route: _vm.route,
              icon: _vm.icon,
              isFullscreen: _vm.primary,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "actions",
                  fn: function () {
                    return [_vm._t("actions")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
          _vm.loading
            ? _c("KLinearLoader", {
                attrs: { type: "indeterminate", delay: false },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main-wrapper", style: _vm.wrapperStyles },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }