var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.active
        ? _c("KIcon", { attrs: { icon: "pointsActive" } })
        : _c("KIcon", { attrs: { icon: "pointsInactive" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }