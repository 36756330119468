var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { class: { "no-diff": !_vm.showDiff } },
    [
      _c(_vm.displayTag, { tag: "component", staticClass: "item" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.coreString("questionNumberLabel", {
                questionNumber: _vm.attemptLog.questionNumber,
              })
            ) +
            "\n  "
        ),
      ]),
      !_vm.isSurvey
        ? [
            _vm.showDiff
              ? _c("AttemptIconDiff", {
                  staticClass: "diff-item item",
                  attrs: {
                    "data-test": "question-attempt-icons",
                    correct: _vm.attemptLog.correct,
                    diff: _vm.attemptLog.diff.correct,
                  },
                })
              : _vm._e(),
            _vm.attemptLog.noattempt
              ? _c("KIcon", {
                  staticClass: "item svg-item",
                  attrs: {
                    "data-test": "question-attempt-icons",
                    icon: "notStarted",
                  },
                })
              : _vm.attemptLog.correct
              ? _c("KIcon", {
                  staticClass: "item svg-item",
                  style: { fill: _vm.$themeTokens.correct },
                  attrs: {
                    "data-test": "question-attempt-icons",
                    icon: "correct",
                  },
                })
              : _vm.attemptLog.error
              ? _c("KIcon", {
                  staticClass: "svg-item",
                  style: { fill: _vm.$themeTokens.annotation },
                  attrs: {
                    "data-test": "question-attempt-icons",
                    icon: "helpNeeded",
                  },
                })
              : !_vm.attemptLog.correct
              ? _c("KIcon", {
                  staticClass: "item svg-item",
                  style: { fill: _vm.$themeTokens.incorrect },
                  attrs: {
                    "data-test": "question-attempt-icons",
                    icon: "incorrect",
                  },
                })
              : _vm.attemptLog.hinted
              ? _c("KIcon", {
                  staticClass: "item svg-item",
                  style: { fill: _vm.$themeTokens.annotation },
                  attrs: {
                    "data-test": "question-attempt-icons",
                    icon: "hint",
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("CoachContentLabel", {
        staticClass: "coach-content-label",
        attrs: {
          value: _vm.attemptLog.num_coach_contents || 0,
          isTopic: false,
        },
      }),
      _vm.attemptLog.missing_resource
        ? _c("KIcon", {
            staticClass: "coach-content-label",
            attrs: { icon: "warning", color: _vm.$themePalette.orange.v_400 },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }