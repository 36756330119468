var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.items, function (item, idx) {
      return _c(
        "KRadioButton",
        _vm._b(
          {
            key: idx,
            attrs: {
              value: _vm.itemValue(item),
              currentValue: _vm.currentValue,
              label: _vm.itemLabel(item),
              description: _vm.description(item),
            },
            on: {
              input: function ($event) {
                return _vm.$emit("update:currentValue", $event)
              },
            },
          },
          "KRadioButton",
          _vm.$attrs,
          false
        ),
        [
          _vm.showUnderButtonSlot(item)
            ? _vm._t("underbutton", null, null, { selected: _vm.selected })
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }